<template>
	<div class="import-status">
		<el-tag v-if="statusDefined" :type="tagType" size="small">{{tagText}}</el-tag>
	</div>
</template>

<script>
	export default {
		name: 'ImportStatus',
		props: ['status'],
		computed: {
			statusDefined() {
				return !isNaN(parseFloat(this.status)) && isFinite(this.status);
			},
			tagType() {
				switch (parseInt(this.status)) {
					case -1:
						return 'info';
					case -2:
						return 'danger';
					case 0:
					case 1:
					case 2:
						return 'primary';
					case 3:
						return 'success';
					default:
						return 'info';
				}
			},
			tagText() {
				switch (parseInt(this.status)) {
					case -1:
						return 'Anulat';
					case -2:
						return 'Eroare';
					case 0:
						return 'Cerere inregistrata';
					case 1:
						return 'Fisier de import descarcat';
					case 2:
						return 'Fisier de import citit';
					case 3:
						return 'Import finalizat';
					default:
						return 'Necunoscut';
				}
			}
		}
	};
</script>

<style>

</style>